import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AuthProvider from "./Auth/AuthContext";
import AccessDenied from "./Pages/AccessDenied";
import MainPage from "./Pages/MainPage";
// import {RouteComponentProps} from "react-router";
import LeftPanel from './Components/LeftPanel';
import PrivateRoute from "./Components/PrivateRoute";
import UsersPage from "./Pages/Users/UsersPage";
import UserInfoPage from "./Pages/Users/UserInfoPage";
import 'moment/locale/ru';
import moment from "moment";
import TokensPage from "./Pages/Tokens/TokensPage";
import TokenStatsPage from "./Pages/Tokens/TokenStatsPage";
import ProtectedRoute from "./Components/PrivateRoute";
import ResellersConfigurePage from "./Pages/Reseller/Manager/ResellersConfigurePage";
import ResellerPersonalPage from "./Pages/Reseller/Personal/ResellerPersonalPage";
// import logo from './logo.svg';
// import './App.scss';

export type IRouteItem = {
    Title: string;
    Path: string;
    Component: any;
    claimsToHave?: string[];
}

moment.locale('ru');
const App = () => {
    const routes: IRouteItem[] = [
        {
            Path: '/',
            Title: 'Main',
            Component: <MainPage/>,
            // claimsToHave: ['r_admin_dash']
        },
        {
            Path: '/users',
            Title: 'Users',
            Component: <UsersPage/>,
            claimsToHave: ['r_admin_dash', 'r_user']
        },
        {
            Path: '/tokens',
            Title: 'Tokens',
            Component: <TokensPage/>,
            claimsToHave: ['r_admin_dash', 'r_tokens']
        },
        {
            Path: '/token-stats',
            Title: 'Tokens stats',
            Component: <TokenStatsPage/>,
            claimsToHave: ['r_admin_dash', 'r_tokens']
        },
        {
            Path: '/reseller-settings',
            Title: 'Reseller settings',
            Component: <ResellersConfigurePage/>,
            claimsToHave: ['r_admin_dash', 'u_reseller']
        },
        {
            Path: '/reseller',
            Title: 'Reseller page',
            Component: <ResellerPersonalPage/>,
            claimsToHave: ['r_admin_dash', 'r_reseller']
        },
        // {
        //     Path: '/statistics',
        //     Title: 'Статистика',
        //     Component: MainPage,
        //     claimsToHave: ['r_admin_dash']
        // },
        // {
        //     Path: '/libraries',
        //     Title: 'Загрузка библиотек',
        //     Component: MainPage,
        //     claimsToHave: ['r_admin_dash']
        // },
        // {
        //     Path: '/plugins',
        //     Title: 'plugins',
        //     Component: MainPage,
        //     claimsToHave: ['r_admin_dash']
        // },
        // {
        //     Path: '/dev',
        //     Title: 'Тестирование',
        //     Component: MainPage,
        //     claimsToHave: ['r_admin_dash']
        // },
        // {
        //     Path: '/payments',
        //     Title: 'Платежи',
        //     Component: MainPage,
        //     claimsToHave: ['r_admin_dash']
        // }
    ]

    return (
        <div className="App">
            <BrowserRouter>
                <AuthProvider>
                    <LeftPanel routes={routes}/>
                    <div className="App__authed">
                        <Routes>
                            <Route path={'/access_denied'} element={<AccessDenied/>}/>
                            {/*{routes.map((routeItem: IRouteItem, index) => {*/}
                            {/*    return <PrivateRoute key={index} exact path={routeItem.Path}*/}
                            {/*                         claimsToHave={routeItem.claimsToHave}*/}
                            {/*                         Component={routeItem.Component}/>*/}
                            {/*})}*/}
                            {routes.map((routeItem, index) => {
                                return <Route path={routeItem.Path} key={index} element={
                                    <ProtectedRoute claimsToHave={routeItem.claimsToHave} redirectPath={'/access_denied'}
                                                    isAllowed={true}>
                                        {routeItem.Component}
                                    </ProtectedRoute>
                                }/>
                            })}
                            <Route path={"/users/:id"} element={
                                <ProtectedRoute claimsToHave={['r_admin_dash', 'r_user']} redirectPath={'/access_denied'}
                                                isAllowed={true}>
                                    <UserInfoPage/>
                                </ProtectedRoute>
                            }/>
                        </Routes>
                    </div>
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
