import {Log, User, UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client';
import Constants from "../Configuration";
export class AuthService {
    public userManager: UserManager;

    constructor() {
        const settings: UserManagerSettings = {
            authority: Constants.stsAuthority,
            client_id: Constants.clientId,
            redirect_uri: `${Constants.clientRoot}signin-callback.html`,
            silent_redirect_uri: `${Constants.clientRoot}silent-renew.html`,
            post_logout_redirect_uri: `${Constants.clientRoot}`,
            response_type: 'code',
            scope: Constants.clientScope,
            // userStore: new WebStorageStateStore({ store: window.localStorage }),
            // clockSkew: 90000,
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true,
            monitorSession: true,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            accessTokenExpiringNotificationTime: 10
            // clockSkew: 1,
        };
        this.userManager = new UserManager(settings);

        Log.logger = console;
        Log.level = Log.INFO;
    }

    public async getUser(): Promise<User | null> {

        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        const path = window.location.pathname;
        const search = window.location.search;
        return this.userManager.signinRedirect({
            data: {
                path,
                search
            }
        });
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }
}
