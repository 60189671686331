import TokensTotalStatsGraph from "./Graphs/TokensTotalStatsGraph";
import TokensTotalStatsGraphByType from "./Graphs/TokensTotalStatsGraphByType";
import {TokenCreateReason} from "../../Api/ClientApi";
import Select from 'react-select'
import {useState} from "react";
import BaseRightPanel from "../../Components/BaseRightPanel";
import {Scrollbars} from "rc-scrollbars";

const TokenStatsPage = () => {
    const [type, setType] = useState(TokenCreateReason.CustomPaypalReseller)
    const options = []
    for (const enumMember in TokenCreateReason) {
        options.push({value: enumMember.toString(), label: enumMember.toString()})
    }

    return <BaseRightPanel title={'Token stats'}>
        <>
            <div className="TokenStatusPage">
                <Scrollbars>
                    <TokensTotalStatsGraph/>
                    <Select options={options}
                            defaultValue={options.find(x => x.value === TokenCreateReason.CustomPaypalReseller)}
                            className='select_type' onChange={(val: any) => setType(val.value as any)}/>
                    <TokensTotalStatsGraphByType reason={type}/>
                </Scrollbars>
            </div>
        </>
    </BaseRightPanel>
}

export default TokenStatsPage;