import useApi from "../../../Api/useApi";
import {TokenStatsDto} from "../../../Api/ClientApi";
import React, {useEffect, useState} from "react";
import ResellerTokenStats from "./ResellerTokenStats";
import BaseRightPanel from "../../../Components/BaseRightPanel";
import VirtuosoTable from "../../../Components/VirtuosoTable";
import ResellerTokenTable from "./ResellerTokenTable";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {Scrollbars} from "rc-scrollbars";
import ResellerGenerateToken from "./ResellerGenerateToken";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            className={'tab_area'}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ResellerPersonalPage = () => {
    const {client} = useApi();
    const [resellerData, setResellerData] = useState([] as TokenStatsDto[])
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const fetchResellers = async () => {
        const data = await client.reseller();
        setResellerData(data);
    }

    useEffect(() => {
        fetchResellers();
    }, []);

    return <BaseRightPanel title={'Reseller page'}>
        <div className={'reseller_personal_page'}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Stats" {...a11yProps(0)} />
                <Tab label="Tokens" {...a11yProps(1)} />
                <Tab label="Generate" {...a11yProps(1)} />
            </Tabs>
            {value === 0 && <Scrollbars>
                <ResellerTokenStats tokenStats={resellerData}/>
            </Scrollbars>}
            {value === 1 && <ResellerTokenTable/>}
            {value === 2 && <ResellerGenerateToken/>}
        </div>
    </BaseRightPanel>
}

export default ResellerPersonalPage;