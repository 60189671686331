export type IContentBlockProps = {
    title: string;
    children: JSX.Element | JSX.Element[];
}

const ContentBlock = ({children, title}: IContentBlockProps) => {
    return <div className="ContentBlock">
        {title && <div className="ContentBlock__title">
            {title}
        </div>}
        <div className="ContentBlock__content">
            {children}
        </div>
    </div>
}

export default ContentBlock;
