import useAuth from "../Auth/useAuth";
import React, {useEffect} from "react";

const AccessDenied = () => {
    const {isAuthenticated, claims, user, state} = useAuth();
    const onLogin = async () => {
        await state.LoginAction();
    }

    return <div className="accessDenied">
        Доступ закрыт
        <button onClick={onLogin}>Login</button>
    </div>
}

export default AccessDenied;
