import React from "react";
import { Navigate, Route } from "react-router";
// import {Redirect, Route} from "react-router-dom";
// import {RouteComponentProps} from "react-router";
import _ from "underscore";
import useAuth from "../Auth/useAuth";
import {Outlet} from "@mui/icons-material";
//
// export type IPrivateRouteProps = {
//     Component: React.ComponentType<any>,
//     // exact?: boolean,
//     // path: string,
//     claimsToHave?: string[]
// }
//
// const PrivateRoute: React.FC<IPrivateRouteProps> = ({Component, claimsToHave, ...rest}) => {
//     const {isAuthenticated, claims} = useAuth();
//
//     const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
//
//     return (
//         <Route
//             {...rest}
//             element={isAuthenticated && hasClaims
//                 ? <Component />
//                 : <Navigate to="/access_denied"/>}
//         />
//     )
// }
// export default PrivateRoute

export type IProtectedRouteProps = {
    isAllowed?: boolean;
    redirectPath: string;
    children: any;
    claimsToHave?: string[]
}

const ProtectedRoute = ({
                            isAllowed,
                            redirectPath = '/landing',
                            children,
                            claimsToHave,
                        }: IProtectedRouteProps) => {
    const {claims} = useAuth();

    const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
    if (!isAllowed || !hasClaims) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;