import {ItemProps, ListProps, Virtuoso} from "react-virtuoso";
import React, {useMemo} from "react";

export interface Column<I, R> {
    id: I;
    label: React.ReactNode;
    render: (row: R) => React.ReactNode;
}

export interface VirtuosoTableProps<I, R> {
    columns: Column<I, R>[];
    rows: R[];
    style: React.CSSProperties;
    loadMore: (index: number) => void;
}

const VirtuosoTable = <I extends string, R>({
                                                columns,
                                                rows,
                                                style,
                                                loadMore
                                            }: VirtuosoTableProps<I, R>) => {
    return (
        <Virtuoso
            style={style}
            totalCount={rows.length}
            endReached={loadMore}
            components={{
                List: React.forwardRef<any, ListProps>(({ children, style }, ref) => {
                    return (
                        <table
                            style={
                                {
                                    "--virtuosoPaddingTop": (style?.paddingTop ?? 0) + "px",
                                    "--virtuosoPaddingBottom": (style?.paddingBottom ?? 0) + "px",
                                    "minWidth": 1200+'px',
                                    "table-layout": 'auto',
                                } as any
                            }
                        >
                            <thead>
                            <tr>
                                {columns.map((col) => (
                                    <th key={col.id}>{col.label}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody ref={ref}>{children}</tbody>
                        </table>
                    );
                }),
                Item: useMemo(
                    () => (props: ItemProps) => {
                        const row = rows[props["data-index"]];

                        return (
                            <tr {...props}>
                                {columns.map((col) => (
                                    <td key={col.id}>{col.render(row)}</td>
                                ))}
                            </tr>
                        );
                    },
                    [columns, rows]
                )
            }}
        />
    );
};

export default VirtuosoTable;
