import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
// @ts-ignore
import faker from 'faker';
import {AdminClient, TokenCreateReason, TokenStatsDto, TokenStatsItemDto} from "../../../Api/ClientApi";
import Constants from "../../../Configuration";
import axios from "axios";
import useApi from "../../../Api/useApi";
import {useEffect, useMemo, useState} from "react";
import _ from "underscore";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const clrs = {
    [TokenCreateReason.CustomPaypalReseller]: 'rgb(255, 99, 132)',
    [TokenCreateReason.Vk]: 'rgb(99, 255, 132)',
    [TokenCreateReason.Digseller]: 'rgb(135, 99, 255)',
    [TokenCreateReason.Paypal]: 'rgb(0, 155, 255)',
    [TokenCreateReason.ForStreamer]: 'rgb(179,123,231)',
    [TokenCreateReason.Reseller]: 'rgba(213,189,36,0.67)',
    [TokenCreateReason.TradeFromSteamAccount]: 'rgb(99,255,200)',
    [TokenCreateReason.Virtyshop]: 'rgb(195,13,220)',
    [TokenCreateReason.VkShop]: 'rgb(208,170,72)',
    [TokenCreateReason.Unset]: 'rgb(0,156,241)',
    [TokenCreateReason.ChinesReseller]: 'rgb(231,90,231)',
}

const TokensTotalStatsGraph = () => {
    const {getStats} = useApi();
    const [labels, setLabels] = useState([] as string[]);
    const [datasets, setDatasets] = useState([] as TokenStatsDto[]);
    const fetch = async () => {
        const statsData = await getStats();
        setLabels(statsData.map((z: TokenStatsDto) => z.date!))
        setDatasets(statsData);
        // statsData.map((statsDto: TokenStatsDto, index: number) => {
        //     statsDto.
        // })
    }

    useEffect(() => {
        fetch();
    }, [])

    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'All activated tokens',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const data = useMemo(() => {
        const allTypesArray: TokenCreateReason[] = [];
        datasets.forEach((value, index) => {
            const localTypes: TokenCreateReason[] = value.tokens!.map((z: TokenStatsItemDto) => z.type!);
            allTypesArray.push(...localTypes)
        })

        const allTypes = _.unique(allTypesArray)

        return {
            labels,
            datasets: allTypes.map((type: TokenCreateReason, index: number) => {
                return {
                    label: type,
                    data: labels.map((label, index1) => {
                        return datasets!.find(x => x.date === label)!.tokens!.filter(z => z.type === type).map(z => z.count)[0];
                    }),
                    backgroundColor: clrs[type]
                }
            }),
        }
    }, [labels, datasets])


    return <Bar options={options} data={data}/>;

}

export default TokensTotalStatsGraph;