import React from "react";
import {AdminClient, TokenStatsDto} from "./ClientApi";
import Constants from "../Configuration";
import axios from "axios";


const client = new AdminClient(Constants.apiEndpoint.substring(0, Constants.apiEndpoint.length - 1), axios)
const useApi = () => {
    const getStats = async (): Promise<TokenStatsDto[]> => {
        return await client.stats();
    }

    return {
        getStats,
        client
    }
}

export default useApi
