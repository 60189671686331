import {useParams} from "react-router";
import ContentBlock from "../../Components/ContentBlock";
import Field from "../../Components/Field";
import {useEffect, useState} from "react";
import {AdminClient, AdminUserEntityDto, ClaimViewModel, SubscribeClient} from "../../Api/ClientApi";
import Constants from "../../Configuration";
import axios from "axios";
import moment from "moment";
import {Scrollbars} from "rc-scrollbars";
import {Box, Button, FormControlLabel, FormGroup, Modal, Slider, Stack, Switch, TextField} from "@mui/material";
import {NavLink} from "react-router-dom";
import Claims from "./Blocks/Claims";
import _ from "underscore";

export type IUserPayment = {
    Source: string;
    Days: number;
    Price: number;
    Date: Date;
    Url: string;
}

export type IUserInfoData = {
    id: string;
    login: string;
    email: string;
    emailConfirmation: boolean;
    isBanned: boolean;
    subscribe: string;
    isDiscordLinked: boolean;
    hwids: any[];
    ips: any[];
    otherAccounts: any[];
    claims: string[];
    payments: any[];
}
const FileDownload = require('js-file-download');
const client = new AdminClient(Constants.apiEndpoint.substring(0, Constants.apiEndpoint.length - 1), axios)
const subClient = new SubscribeClient(Constants.apiEndpoint.substring(0, Constants.apiEndpoint.length - 1), axios)
const UserInfoPage = () => {
    let {id} = useParams<{ id: string | undefined }>();
    const [user, setUserData] = useState(undefined as AdminUserEntityDto | undefined)
    const [isOpenModalAddSub, setOpenModalAddSub] = useState(false)
    const [isOpenModalBanUser, setOpenModalBanUser] = useState(false)
    const [isOpenModalForceResetPassword, setOpenModalForceResetPassword] = useState(false)
    const [banReason, setBanReason] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [notifyWithEmailOnForcePassword, setNotifyWithEmailOnForcePassword] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [daysToAdd, setDaysToAdd] = useState(3 as number)

    const fetchUserData = () => {
        setLoading(true);
        client.users(id as string).then(data => {
            setUserData(data)
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchUserData();
    }, [id])

    const onSaveClaimsHandler = async (claims: { [key: string]: boolean; } | undefined) => {
        const targetClaims = claims ?? {};
        const bodyClaims: ClaimViewModel[] = Object.keys(targetClaims).map((x) => {
            return {name: x, isEnabled: targetClaims[x]} as ClaimViewModel
        })
        await client.update(id as string, bodyClaims)
        fetchUserData();
    }

    const actionWithRestartData = async (act: () => void) => {
        await act();
        fetchUserData();
    }

    const clearPcDataHandler = async () => {
        await actionWithRestartData(async () => {
            await client.clearPcData(id as string)
        });
    }

    const clearHwid = async () => {
        await actionWithRestartData(async () => {
            await client.reset(id as string)
        });
    }

    const clearVkHandler = async () => {
        await actionWithRestartData(async () => {
            await client.clearVk(id as string)
        });
    }
    const clearDiscordHandler = async () => {
        await actionWithRestartData(async () => {
            await client.clearDiscord(id as string)
        });
    }

    const clearFreeSubHandler = async () => {
        await actionWithRestartData(async () => {
            await client.clearFreeSub(id as string)
        });
    }

    const clearPaidSubHandler = async () => {
        await actionWithRestartData(async () => {
            await client.clearPaidSub(id as string)
        });
    }

    const unbanUserHandler = async () => {
        await actionWithRestartData(async () => {
            await client.unban(id as string);
        });
    }
    const banUserHandler = async (reason: string) => {
        await actionWithRestartData(async () => {
            await client.ban(id as string, reason);
        });
    }

    const forceChangePassword = async (password: string, notify: boolean) => {
        await client.forceChangePassword(id as string, password, notify);
    }

    const deletePaymentByIdHandler = async (paymentId: number) => {
        await actionWithRestartData(async () => {
            await client.deletePayment(paymentId);
        });
    }
    const downloadConfigHandler = async (fileId: string) => {
        const fileResult = await client.downloadFile(fileId);
        console.log('fileResult', fileResult)
        FileDownload(fileResult.data, `Config.zip`);
    }


    if (!id)
        return null;


    const style: any = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    // @ts-ignore
    return <Scrollbars autoHeight={false}>
        <div className="UserInfoPage">
            {user && <>
                <div className="UserInfoPage__col">
                    <ContentBlock title={'Информация о пользователе'}>
                        <Field title={'Id данного юзера'}>
                            {user.id}
                        </Field>
                        <Field title={'Логин'}>
                            {user?.userName}
                        </Field>
                        <Field title={'Почта'}>
                            {user?.email}
                        </Field>
                        <Field title={'Статус бана'}>
                            {user?.isBanned ? `Забанен по причине: ${user.banReason}` : 'Не забанен'}
                        </Field>
                        <Field title={'Есть подписка?'}>
                            {user?.hasSub ? `Есть, до ${moment(user?.subWorksUntil).format('L LTS')}` : 'Нет'}
                        </Field>
                        <Field title={'Есть пробный период?'}>
                            {user?.hasFreeSub ? `Есть, до ${moment(user?.freeSubWorksUntil).format('L LTS')}` : 'Нет'}
                        </Field>
                        <Field title={'Подписка'}>
                            <div className="left">
                                До - {moment(user?.finalWorksUntil).format('L LTS')}
                            </div>
                        </Field>
                    </ContentBlock>
                    <ContentBlock title={'Фейки юзера по системе'}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={50} autoHeight={true} autoHeightMax={500}>
                            <div style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                gap: '10px'
                            }}>
                                {_.uniq(user.dirtyData?.anyWithSystemIds ?? [])?.map((userId, index) => {
                                    return <NavLink className="dirty_ip__item"
                                                    style={{
                                                        border: '1px solid white',
                                                        padding: '3px 5px',
                                                        color: 'white'
                                                    }}
                                                    to={`/users/${userId}`}>{userId}</NavLink>
                                })}
                            </div>
                        </Scrollbars>
                    </ContentBlock>
                    <ContentBlock title={'Фейки юзера по стиму'}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={50} autoHeight={true} autoHeightMax={500}>
                            <div style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                gap: '10px'
                            }}>
                                {_.uniq(user.dirtyData?.anyWithSteamIds ?? []).map((userId, index) => {
                                    return <NavLink className="dirty_ip__item"
                                                    style={{
                                                        border: '1px solid white',
                                                        padding: '3px 5px',
                                                        color: 'white'
                                                    }}
                                                    to={`/users/${userId}`}>{userId}</NavLink>
                                })}
                            </div>
                        </Scrollbars>
                    </ContentBlock>
                    <ContentBlock title={'Права'}>
                        {/*<Scrollbars autoHeightMin={300} autoHeight={true} autoHeightMax={500}>*/}
                        <Claims claims={user.claims} onSave={onSaveClaimsHandler}/>
                        {/*</Scrollbars>*/}
                    </ContentBlock>
                    <ContentBlock title={'Управление пользователем'}>
                        <div className="user_control_area">
                            <Button variant="contained" color={'success'} onClick={() => setOpenModalAddSub(true)}>Дать
                                подписку</Button>
                            {/*<Button variant="contained" color={'success'}>Дать фри подписку</Button>*/}
                            <Button variant="contained" color={'success'}
                                    onClick={unbanUserHandler}>Разблокировать</Button>
                            <Button variant="contained" color={'error'}
                                    onClick={() => setOpenModalBanUser(true)}>Заблокировать</Button>
                            <Button variant="contained" color={'error'} onClick={clearFreeSubHandler}>Забрать фри
                                подписку</Button>
                            <Button variant="contained" color={'error'} onClick={clearPaidSubHandler}>Забрать платную
                                подписку</Button>
                            <Button variant="contained" onClick={() => setOpenModalForceResetPassword(true)}>Сбросить
                                пароль</Button>
                            <Button variant="contained" color={'primary'} onClick={clearVkHandler}>Clear vk data</Button>
                            <Button variant="contained" color={'primary'} onClick={clearDiscordHandler}>Clear discord data</Button>
                            <Button variant="contained" color={'primary'} onClick={clearPcDataHandler}>Clear pc data</Button>
                            <Button variant="contained" color={'primary'} onClick={clearHwid}>Hwid clear</Button>
                        </div>
                    </ContentBlock>
                    <Modal
                        hideBackdrop
                        open={isOpenModalForceResetPassword}
                        onClose={() => setOpenModalForceResetPassword(false)}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{...style, width: 600}}>
                            <Stack direction={'column'}>
                                <h2 id="child-modal-title">Введи новый пароль</h2>
                                <TextField
                                    id="outlined-number"
                                    label="Новый пароль"
                                    value={newPassword}
                                    onChange={(e: any) => setNewPassword(e.target.value)}
                                />
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={notifyWithEmailOnForcePassword}
                                                                       onClick={event => setNotifyWithEmailOnForcePassword(!notifyWithEmailOnForcePassword)}
                                                                       defaultChecked/>} label="Уведомить по почте"/>
                                </FormGroup>
                                <Stack direction={'row'}>
                                    <Button onClick={async () => {
                                        forceChangePassword(newPassword, notifyWithEmailOnForcePassword);
                                    }} color={'success'} disabled={!newPassword}>Изменить пароль</Button>
                                    <Button onClick={() => setOpenModalForceResetPassword(false)}>Закрыть</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Modal>
                    <Modal
                        hideBackdrop
                        open={isOpenModalAddSub}
                        onClose={() => setOpenModalAddSub(false)}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{...style, width: 600}}>
                            <Stack direction={'column'}>
                                <h2 id="child-modal-title">Выдать подписку</h2>
                                <Slider min={-180} max={180} aria-label="Default"
                                        valueLabelDisplay="auto"
                                        value={daysToAdd}
                                        onChange={(event: React.SyntheticEvent | Event, value: number | number[]) => setDaysToAdd(value as number)}/>
                                <TextField
                                    id="outlined-number"
                                    label="Number"
                                    type="number"
                                    value={daysToAdd}
                                    onChange={(e: any) => {
                                        const val = parseInt(e.target.value);
                                        if (val > 180)
                                            setDaysToAdd(180);
                                        else if (val < -180) {
                                            setDaysToAdd(-180);
                                        } else
                                            setDaysToAdd(val);
                                    }}
                                />
                                <Stack direction={'row'}>
                                    <Button onClick={async () => {
                                        await subClient.addSubDaysToUser(id as any, daysToAdd);
                                        fetchUserData();
                                    }} color={'success'}>Выдать</Button>
                                    <Button onClick={() => setOpenModalAddSub(false)}>Закрыть</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Modal>
                    <Modal
                        hideBackdrop
                        open={isOpenModalBanUser}
                        onClose={() => setOpenModalBanUser(false)}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{...style, width: 600}}>
                            <Stack direction={'column'}>
                                <h2 id="child-modal-title">Введи причину бана</h2>
                                <TextField
                                    id="outlined-number"
                                    label="Причина бана"
                                    value={banReason}
                                    onChange={(e: any) => setBanReason(e.target.value)}
                                />
                                <Stack direction={'row'}>
                                    <Button onClick={async () => {
                                        banUserHandler(banReason);
                                    }} color={'success'}>Выдать</Button>
                                    <Button onClick={() => setOpenModalBanUser(false)}>Закрыть</Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Modal>
                    <ContentBlock title={'История по IP'}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={100} autoHeight={true} autoHeightMax={500}>
                            {user.ipInfo?.map((ip, index) => {
                                return <div className="ip__item">
                                    <div className="ip__item-ip">{ip.ip}</div>
                                    <div className="ip__item-ip">({ip.count}шт.)</div>
                                    <div className="ip__item-cis">{ip.isCis ? 'СНГ' : 'Не СНГ'}</div>
                                    <div className="ip__item-cis">{moment(ip.lastDate).format('L LTS')}</div>
                                </div>
                            })}
                        </Scrollbars>
                    </ContentBlock>
                    <ContentBlock title={`Конфиги`}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={300} autoHeight={true} autoHeightMax={500}>
                            {user.configs?.map((config, index) => {
                                return <div className="configs">
                                    {config.myConfigs?.map((userConfig, index) => {
                                        return <div className="user_config">
                                            <Field title={'Имя конфига'}>
                                                {userConfig.name}
                                            </Field>
                                            <Field title={'Id'}>
                                                {userConfig.id}
                                            </Field>
                                            <Field title={'Code'}>
                                                {userConfig.code}
                                            </Field>
                                            <Field title={'Создатель конфига'}>
                                                {userConfig.authorName}
                                            </Field>
                                            <Field title={'Айди файла'}>
                                                {userConfig.fileId}
                                            </Field>
                                            <Field title={'Флаг активности'}>
                                                {userConfig.isActive ? 'Активный' : 'Не активный'}
                                            </Field>
                                            <Field title={'Флаг публичности'}>
                                                {userConfig.isPublic ? 'Публичный' : 'Приватный'}
                                            </Field>
                                            <div className="user_config__action">
                                                <Button disabled color={'primary'}>Сделать активным</Button>
                                                <Button disabled color={'error'}>Удалить</Button>
                                                <Button disabled={!userConfig?.fileId}
                                                        onClick={() => downloadConfigHandler(userConfig?.fileId as string)}
                                                        color={'primary'}>Скачать файл конфига</Button>
                                            </div>

                                        </div>
                                    })}
                                </div>
                            })}
                        </Scrollbars>
                    </ContentBlock>
                </div>
                <div className="UserInfoPage__col">
                    <ContentBlock title={'История покупок'}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={300} autoHeight={true} autoHeightMax={500}>
                            {user.payments?.map((payment, index) => {
                                return <div className="payments__item">
                                    <div className="payments__item-id">Причина - {payment.reason}</div>
                                    <div className="payments__item-id">Дни {payment.days}</div>
                                    {payment.isToken && <>
                                        <div className="payments__item-id">Причина - {payment.tokenReason}</div>
                                    </>}
                                    <div className="payments__item-id">Валюта - {payment.currency}</div>
                                    <div className="payments__item-id">Цена - {payment?.price}</div>
                                    <div className="payments__item-id">Дата
                                        - {payment.paymentDate?.format('L LTS')}</div>
                                    <Button color={'error'}
                                            onClick={() => deletePaymentByIdHandler(payment.id as number)}>Удалить</Button>
                                </div>
                            })}
                        </Scrollbars>
                    </ContentBlock>
                    <ContentBlock title={`История игр: ${user.games?.length}шт`}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={300} autoHeight={true} autoHeightMax={500}>
                            {user.games?.map((game, index) => {
                                return <div className="games_item">
                                    <div className="games_item-id">{game.matchId}</div>
                                    <div className="games_item-time">{game.createdTime?.format('L LTS')}</div>
                                </div>
                            })}
                        </Scrollbars>
                    </ContentBlock>
                    <ContentBlock title={`Привязки`}>
                        <div className="links">
                            <div className="links__item">
                                <div className="links__item-title">ВК</div>
                                {user.vkInfo && <div className="links__item-content">
                                    <Field title={'Айди'}>
                                        {user.vkInfo?.vkUserId}
                                    </Field>
                                    <Field title={'Пользователь'}>
                                        {`${user.vkInfo?.lastName} - ${user.vkInfo?.firstName}`}
                                    </Field>
                                </div>}
                            </div>
                            <div className="links__item">
                                <div className="links__item-title">Дискорд</div>
                                {user.discord && <div className="links__item-content">
                                    <Field title={'Айди'}>
                                        {user.discord?.discordId}
                                    </Field>
                                    <Field title={'Логин'}>
                                        {user.discord?.username}
                                    </Field>
                                    <Field title={'Почта'}>
                                        {user.discord?.email}
                                    </Field>
                                </div>}
                                {/*<div className="links__item-content">*/}

                                {/*</div>*/}
                                {/*<div className="links__item-content">*/}

                                {/*</div>*/}
                                {/*<div className="links__item-content">{user.discord?.discordId}</div>*/}
                                {/*<div className="links__item-content">{user.discord?.username}</div>*/}
                                {/*<div className="links__item-content">{user.discord?.email}</div>*/}
                            </div>
                        </div>
                    </ContentBlock>
                    <ContentBlock title={`Конфигурация ПК`}>
                        {/*// @ts-ignore*/}
                        <Scrollbars autoHeightMin={300} autoHeight={true} autoHeightMax={500}>
                            <>
                                {user.pcInfos?.map((pcInfo, index) => {
                                    return <div className="pcInfo__item">
                                        <Field title={'computerName'}>
                                            {pcInfo.computerName}
                                        </Field>
                                        <Field title={'cpuHash'}>
                                            {pcInfo.cpuHash}
                                        </Field>
                                        <Field title={'hddSerial'}>
                                            {pcInfo.hddSerial}
                                        </Field>
                                        <Field title={'macAddress'}>
                                            {pcInfo.macAddress}
                                        </Field>
                                        <Field title={'steamId'}>
                                            {pcInfo.steamId}
                                        </Field>
                                    </div>
                                })}
                            </>
                        </Scrollbars>
                    </ContentBlock>

                </div>
            </>}
        </div>
    </Scrollbars>
}

export default UserInfoPage;
