import {Scrollbars} from "rc-scrollbars";

export type IBaseRightPanelProps = {
    title?: string;
    children: JSX.Element;
}

const BaseRightPanel = ({title, children}: IBaseRightPanelProps) => {
    return <div className="BaseRightPanel">
        {title && <div className="BaseRightPanel__title">
            {title}
        </div>}
        <div className="BaseRightPanel__content">
            {/*<Scrollbars >*/}
                {children}
            {/*</Scrollbars>*/}
        </div>
    </div>
}

export default BaseRightPanel;
