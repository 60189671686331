import Select from "react-select";
import {useState} from "react";
import {Box, Button} from "@mui/material";
import useApi from "../../../Api/useApi";

const ResellerGenerateToken = () => {
    const {client} = useApi();
    const [days, setDays] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [tokenString, setTokenString] = useState([] as string[]);
    const options = [1, 3, 7, 14, 30, 90, 180].map(value => {
        return {label: `${value} days`, value: value.toString()}
    })

    const onGenerateHandler = async () => {
        setLoading(true)
        const tokenResult = await client.generate(days);
        setTokenString([...tokenString, tokenResult]);
        setLoading(false)
    }

    return <div className={'reseller_generate_token_page'}>
        <Select options={options} defaultValue={options[0]}
                className='select_type' onChange={(val: any) => setDays(val.value as any)}/>
        <Box mt={2}>
            <Button variant={'contained'} onClick={onGenerateHandler} disabled={isLoading}>Generate new token</Button>
        </Box>
        <Box mt={2}>
            {tokenString.map((token) => {
                return <div>{token}</div>
            })}

        </Box>
    </div>
}

export default ResellerGenerateToken;