import axios from 'axios';
import {AuthService} from './AuthService';

export enum RequestType {
    GET,
    POST,
    PUT,
    DELETE
}

export type ResponseType =
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream'

export class ApiService {
    private authService: AuthService;

    constructor(authService: any) {
        // this.authService = new AuthService();
        this.authService = authService;
    }

    public static updateToken(access_token: string) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    }

    private static _callApi(endpoint: string, token: string, data: any, type: RequestType, responseType: ResponseType = 'json') {
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        };
        switch (type) {
            case RequestType.GET:
                return axios.get(endpoint, {headers, responseType},);
            case RequestType.POST:
                return axios.post(endpoint, data, {headers});
            case RequestType.PUT:
                return axios.put(endpoint, data, {headers});
            case RequestType.DELETE:
                return axios.delete(endpoint, {headers});
        }
    }

    public callApi(url: string, data: any = undefined, type: RequestType = RequestType.GET, responseType: ResponseType = 'json'): Promise<any> {
        return this.authService.getUser().then(user => {
            if (user && user.access_token) {
                return ApiService._callApi(url, user.access_token, data, type, responseType).catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        if (error?.response?.status === 401) {
                            return this.authService.renewToken().then(renewedUser => {
                                ApiService.updateToken(renewedUser.access_token)
                                return ApiService._callApi(url, renewedUser.access_token, data, type, responseType).catch(error => {
                                    if (error.response) {
                                        console.log(error.response.data);
                                        console.log(error.response.status);
                                        console.log(error.response.headers);
                                        throw error.data;
                                    } else if (error.request) {
                                        console.log(error)
                                        throw error;
                                    } else {
                                        console.log(error)
                                        throw error.message;
                                    }
                                });
                            });
                        }
                    } else if (error.request) {
                        console.log(error.request)
                        throw error;
                    } else {
                        console.log(error.message)
                        throw error.message;
                    }
                    console.log(error)
                    throw error;
                });
            } else if (user) {
                return this.authService.renewToken().then(renewedUser => {
                    ApiService.updateToken(renewedUser.access_token)
                    return ApiService._callApi(url, renewedUser.access_token, data, type, responseType);
                });
            } else {
                throw new Error('user is not logged in');
            }
        });
    }
}
