import {AdminClient, TokenCreateReason} from "../../Api/ClientApi";
import Constants from "../../Configuration";
import axios from "axios";
import BaseRightPanel from "../../Components/BaseRightPanel";
import VirtuosoTable, {Column} from "../../Components/VirtuosoTable";
import {useCallback, useEffect, useState} from "react";
import useDebouncedCallback from "../../Hooks/useDebouncedCallback";
import {NavLink} from "react-router-dom";
import * as moment from "moment";

const client = new AdminClient(Constants.apiEndpoint.substring(0, Constants.apiEndpoint.length - 1), axios);
const pageSize = 25;


interface Row {
    tokenString: string;
    activatedBy: string | undefined;
    activateTime: moment.Moment | undefined;
    createdTime: moment.Moment | undefined;
    generatedBy: string;
    isUsed: string;
    lifeTime: string;
    createReason: TokenCreateReason;
}

const TokensPage = () => {
    const [rows, setRows] = useState([] as Row[]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [ignorePage, setIgnorePage] = useState(false);


    const fetch = async (clearData: boolean = false) => {
        const tokens = await client.tokens(clearData ? 1 : page, pageSize, search);
        const newData: Row[] = tokens.map((token, index) => {
            return {
                tokenString: token.tokenString ?? '',
                activatedBy: token.activatedBy ?? '',
                activateTime: token.activateTime ?? '',
                generatedBy: token.generatedBy ?? '',
                isUsed: token.isUsed ?? '',
                lifeTime: token.lifeTime ?? '',
                createReason: token.createReason ?? '',
                createdTime: token.createdTime ?? '',
            } as Row
        });
        if (clearData) {
            setRows((oldRows: Row[]) => {
                return newData;
            })
        } else {
            setRows((oldRows: Row[]) => {
                return [...oldRows, ...newData]
            })
        }

    }
    const saveOnChange = useDebouncedCallback(fetch, 150)
    const loadMore = useCallback(() => {
        setPage(prevState => {
            return prevState + 1;
        })
    }, [setPage])

    useEffect(() => {
        if (!ignorePage)
            saveOnChange(false);
    }, [page])
    useEffect(() => {
        setIgnorePage(true);
        setPage(1);
        saveOnChange(true);
        setIgnorePage(false);
    }, [search]);

    const getUserLink = (userId: string) => {
        return <NavLink style={{color: "white"}}
                        to={`/users/${userId}`}>{userId}</NavLink>;;
    }

    const columns: Column<string, Row>[] = [
        {
            id: "tokenString",
            label: "Code",
            render: (r) => r.tokenString
        },
        {
            id: "generatedBy",
            label: "Generated by",
            render: (r) => {
                if (r.generatedBy) {
                    return getUserLink(r.generatedBy)
                } else {
                    return '-'
                }
            }
        },
        {
            id: "activatedBy",
            label: "Активировал",
            render: (r) => {
                if (r.activatedBy) {
                    return getUserLink(r.activatedBy);
                } else {
                    return '-'
                }
            }
        },
        {
            id: "activateTime",
            label: "Время активации",
            render: (r) => {
                if (r.activateTime) {
                    return r.activateTime.format('L LTS')
                } else {
                    return '-'
                }
            }
        },
        {
            id: "lifeTime",
            label: "Days",
            render: (r) => r.lifeTime
        },
        {
            id: "createReason",
            label: "Type",
            render: (r) => r.createReason
        },
        {
            id: "createdTime",
            label: "Created",
            render: (r) => {
                if (r.createdTime) {
                    return r.createdTime.format('L LTS')
                } else {
                    return '-'
                }
            }
        },
    ];
    return <div className={'TokensPage'}>
        <BaseRightPanel title={'Tokens page'}>
            <>
                <div className="search_block">
                    <input className={'search_block__input'} placeholder={'Поиск'} value={search}
                           onChange={event => setSearch(event.target.value)}/>
                </div>

                <VirtuosoTable columns={columns} rows={rows} style={{}} loadMore={loadMore}/>
            </>
        </BaseRightPanel>
    </div>
}

export default TokensPage;