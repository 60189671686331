export type IFieldProps = {
    title: string;
    children: JSX.Element | string | number | undefined;
}

const Field = ({children, title}: IFieldProps) => {
    return <div className="Field">
        <div className="Field__title">{title}</div>
        <div className="Field__content">{children}</div>
    </div>
}

export default Field;
