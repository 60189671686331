import useAuth from "../Auth/useAuth";
import React, {useEffect} from "react";
const MainPage = () => {
    const {isAuthenticated, claims, user, state} = useAuth();
    const onLogin = async () => {
        await state.LoginAction();
    };
    const onRefresh = async () => {
        // const renewedUser = await state.authService.renewToken();
        // state.apiService.updateToken(renewedUser.access_token)
        await state.RefreshAction();
    };
    const onLogout = async () => {
        await state.LogoutAction();
    };

    useEffect(() => {

    },[])

    return <div className='MainPage'>
        <div className="kek">IsAuthenticated: {isAuthenticated ? '+' : '-'}</div>
        {/*<div className="kek">{`user: ${JSON.stringify(user)}`}</div>*/}
        <div className="kek">Name: {user?.profile?.name}</div>
        <button onClick={onLogin}>Login</button>
        <button onClick={onRefresh}>Refresh</button>
        <button onClick={onLogout}>Logout</button>
    </div>
}

export default MainPage;
