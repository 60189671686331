import useApi from "../../../Api/useApi";
import {useEffect, useState} from "react";
import {Reseller, TokenCreateReason} from "../../../Api/ClientApi";
import Select from "react-select";
import {Button, Input, TextField} from "@mui/material";
import BaseRightPanel from "../../../Components/BaseRightPanel";

const ResellersConfigurePage = () => {
    const {client} = useApi();
    const [resellers, setResellers] = useState([] as Reseller[])
    const [type, setType] = useState(TokenCreateReason.CustomPaypalReseller)
    const [userId, setUserId] = useState('')
    const options = []
    for (const enumMember in TokenCreateReason) {
        options.push({value: enumMember.toString(), label: enumMember.toString()})
    }

    const fetchResellers = () => {
        client.listAll().then((resellers: Reseller[]) => {
            setResellers(resellers);
        })
    }

    useEffect(() => {
        fetchResellers();
    }, []);

    const addNewReseller = async () => {
        const newReseller = await client.add2(userId, type);
        console.log('newReseller', newReseller);
        fetchResellers();
    }

    return <div className={'ResellerConfigurePage'}>
        <BaseRightPanel title={'Reseller manager'}>
            <>
                <h2>Добавить нового реселлера</h2>
                <TextField id="standard-basic" label="User Id" variant="standard"
                           onChange={(event) => setUserId(event.target.value)}/>
                <Select options={options}
                        defaultValue={options.find(x => x.value === TokenCreateReason.CustomPaypalReseller)}
                        className='select_type' onChange={(val: any) => setType(val.value as any)}/>
                <Button variant={'contained'} onClick={addNewReseller}>Добавить</Button>
                <h2>Список реселлеров</h2>
                {resellers.map(x => {
                    return <div>
                        {x.userInfo?.userId} - {x.personalReason}
                    </div>
                })}

            </>
        </BaseRightPanel>
    </div>
}

export default ResellersConfigurePage;