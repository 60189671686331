import {Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import Scrollbars from "rc-scrollbars";
import {useEffect, useState} from "react";

export type IClaimsProps = {
    claims: { [key: string]: boolean; } | undefined;
    onSave: (claims: { [key: string]: boolean; } | undefined) => void;
}

const Claims = (props: IClaimsProps) => {
    const [isDirty, setIsDirty] = useState(false);
    const [currentClaims, setCurrentClaims] = useState({} as ({ [key: string]: boolean; } | undefined));
    const mapClaimName = (claim: string): string => {
        if (claim.startsWith("r_")) {
            return `read ${claim.replace('r_', '')}`;
        }
        if (claim.startsWith("d_")) {
            return `delete ${claim.replace('d_', '')}`;
        }
        if (claim.startsWith("u_")) {
            return `update ${claim.replace('u_', '')}`;
        }
        if (claim.startsWith("c_")) {
            return `create ${claim.replace('c_', '')}`;
        }
        if (claim.startsWith("a_")) {
            return `approve ${claim.replace('a_', '')}`;
        }
        return claim;
    }

    useEffect(() => {
        setCurrentClaims(props.claims);
        setIsDirty(false);
    }, [props.claims])

    const handleChange = (claim: string) => {
        setIsDirty(true);
        let checked = false;
        if (currentClaims) {
            checked = currentClaims[claim];
        }
        setCurrentClaims({...currentClaims, [claim]: !checked})
    }

    return <>
        {/*// @ts-ignore*/}
        <Scrollbars autoHeightMin={300} autoHeight={true} autoHeightMax={500}>
            <div className="claims">
                <FormGroup>
                    {Object.keys(currentClaims ?? {}).map((claim, index) => {
                        let checked = false;
                        if (currentClaims) {
                            checked = currentClaims[claim];
                        }
                        return <FormControlLabel
                            control={<Checkbox checked={checked}
                                               onClick={(g) => handleChange(claim)}/>}
                            label={mapClaimName(claim)}/>
                    })}

                </FormGroup>
            </div>
        </Scrollbars>
        <Button variant="contained" color={'success'} disabled={!isDirty} onClick={() => props.onSave(currentClaims)}>Сохранить изменения</Button>
    </>
}

export default Claims;