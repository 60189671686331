import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
// @ts-ignore
import faker from 'faker';
import {useEffect, useMemo, useState} from "react";
import _ from "underscore";
import {TokenStatsDto, TokenStatsItemDto} from "../../../Api/ClientApi";
import BaseRightPanel from "../../../Components/BaseRightPanel";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface INumberClr {
    [key: number]: string;
}

const clrs: INumberClr = {
    1: 'rgb(255, 99, 132)',
    3: 'rgb(99, 255, 132)',
    7: 'rgb(135, 99, 255)',
    14: 'rgb(0, 155, 255)',
    30: 'rgb(179,123,231)',
    90: 'rgba(213,189,36,0.67)',
    180: 'rgb(99,255,200)',
}

export type ITokensTotalStatsGraphByTypeProps = {
    tokenStats: TokenStatsDto[];
}

const ResellerTokenStats = (props: ITokensTotalStatsGraphByTypeProps) => {
    const [labels, setLabels] = useState([] as string[]);
    const datasets = props.tokenStats;


    useEffect(() => {
        setLabels(props.tokenStats.map((z: TokenStatsDto) => z.date!))
    }, [props.tokenStats])

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Tokens: ',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const data = useMemo(() => {
        const allTypesArray: number[] = [];
        datasets.forEach((value, index) => {
            const localTypes: number[] = value.tokens!.map((z: TokenStatsItemDto) => z.days!);
            allTypesArray.push(...localTypes)
        })

        const allTypes = _.unique(allTypesArray)
        return {
            labels,
            datasets: allTypes.map((number: number, index: number) => {
                return {
                    label: `${number} days`,
                    data: labels.map((label, index1) => {
                        return datasets!.find(x => x.date === label)!.tokens!.filter(z => z.days === number).map(z => z.count)[0];
                    }),
                    backgroundColor: clrs[number!]
                }
            }),
        }
    }, [labels, datasets])

    return <Bar options={options} data={data}/>

}

export default ResellerTokenStats;