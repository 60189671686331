import {NavLink} from "react-router-dom";
import {IRouteItem} from "../App";
import useAuth from "../Auth/useAuth";
import _ from "underscore";

type Props = {
    routes: IRouteItem[]
}

const LeftPanel = (props: Props) => {
    const {isAuthenticated, claims} = useAuth();
    if (!isAuthenticated)
        return null;

    const allowToSee = (claimsToHave?: string[]) => {
        const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
        if (!claimsToHave || claimsToHave.length === 0)
            return true;
        return hasClaims;
    }

    return <div className="LeftPanel">
        <div className="LeftPanel__header">Admin Divine</div>
        <div className="LeftPanel__vertical_menu">
            {props.routes.filter(x => allowToSee(x.claimsToHave)).map((item, index) => {
                return <div className="menu_item">
                    <NavLink className="link" to={item.Path}>
                        <div className="title">{item.Title}</div>
                        <div className="arrow">{`>`}</div>
                    </NavLink>
                </div>
            })}
        </div>
    </div>
}

export default LeftPanel;
