import BaseRightPanel from "../../Components/BaseRightPanel";
import VirtuosoTable, {Column} from "../../Components/VirtuosoTable";
import starWarsNames from "starwars-names";
import {NavLink} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {AdminClient} from "../../Api/ClientApi";
import Constants from "../../Configuration";
import axios from "axios";
import _ from "underscore";
import useDebouncedCallback from "../../Hooks/useDebouncedCallback";

interface Row {
    login: string;
    // discord: string;
    email: string;
    userId: string;
    isBanned: boolean;
}

const client = new AdminClient(Constants.apiEndpoint.substring(0, Constants.apiEndpoint.length - 1), axios)
const pageSize = 35;
const UsersPage = () => {

    const [rows, setRows] = useState([] as Row[]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [ignorePage, setIgnorePage] = useState(false);


    const fetch = async (clearData: boolean = false) => {
        const users = await client.usersAll(clearData ? 1 : page, pageSize, search);
        const newData = users.map((user, index) => {
            return {
                login: user.userName ?? '',
                // discord: user.discord?.discordId ?? '',
                email: user.email ?? '',
                userId: user.id ?? '',
                isBanned: false
            }
        });
        if (clearData) {
            setRows((oldRows: Row[]) => {
                return newData;
            })
        } else {
            setRows((oldRows: Row[]) => {
                return [...oldRows, ...newData]
            })
        }

    }
    const saveOnChange = useDebouncedCallback(fetch, 150)
    const loadMore = useCallback(() => {
        setPage(prevState => {
            return prevState + 1;
        })
    }, [setPage])

    useEffect(() => {
        if (!ignorePage)
            saveOnChange(false);
    }, [page])
    useEffect(() => {
        setIgnorePage(true);
        setPage(1);
        saveOnChange(true);
        setIgnorePage(false);
    }, [search])

    const columns: Column<string, Row>[] = [
        {
            id: "userId",
            label: "Id",
            render: (r) => r.userId
        },
        {
            id: "login",
            label: "Логин",
            render: (r) => r.login
        },
        {
            id: "email",
            label: "Почта",
            render: (r) => r.email
        },
        {
            id: "isBanned",
            label: "Бан статус",
            render: (r) => {
                return r.isBanned ? <div className="banned banned--yes">Забанен</div> :
                    <div className="banned banned--nope">Не забанен</div>
            }
        },
        {
            id: "action",
            label: "Действие",
            render: (r) => {
                return <div className="actions">
                    <NavLink className="link" to={'/users/' + r.userId}>Edit</NavLink>
                    {/*<button>Edit</button>*/}
                    {/*<button>Delete</button>*/}
                </div>
            }
        },
    ];
    return <div className="UsersPage">
        {/*<CSSTransition in={true} timeout={180} classNames="fade" unmountOnExit>*/}
        <BaseRightPanel title={'Users page'}>
            <>
                <div className="search_block">
                    <input className={'search_block__input'} placeholder={'Поиск'} value={search}
                           onChange={event => setSearch(event.target.value)}/>
                </div>

                <VirtuosoTable columns={columns} rows={rows} style={{}} loadMore={loadMore}/>
            </>
        </BaseRightPanel>
        {/*</CSSTransition>*/}
    </div>
}

export default UsersPage;
