import React, {useEffect, useMemo} from "react";
import {AuthService} from "../Services/AuthService";
import {ApiService} from "../Services/ApiService";
import {User} from "oidc-client";
import {useLocation} from "react-router-dom";
import axios from "axios";
import decode from "jwt-decode";


export interface IUserToken {
    id_token: string
    session_state: string
    access_token: string
    refresh_token: string
    token_type: string
    scope: string
    profile: IProfile
    expires_at: number
}

export interface IProfile {
    s_hash: string
    sid: string
    sub: string
    auth_time: number
    idp: string
    preferred_username: string
    name: string
    amr: string[]
}


export enum IAuthTypeStatus {
    Pending,
    LoggedIn,
    LoggedOut,
    InError
}

export type IAuthType = {
    status: IAuthTypeStatus,
    error: Error | null,
    user: User | null,
    apiService: any,
    authService: any,
    LoginAction: Function,
    LogoutAction: Function,
    RefreshAction: Function,
    claims: string[],


}

export const AuthContext = React.createContext({
    status: IAuthTypeStatus.Pending,
    error: null,
    user: null,
    apiService: null,
    authService: null,
    LoginAction: Function,
    LogoutAction: Function,
    RefreshAction: Function,
    claims: [],
    notifications: {
        visited: false,
        data: [],
        setVisited: undefined
    },

} as IAuthType);

const AuthProvider = ({children}: any) => {
    const authService = useMemo(() => new AuthService(), [])
    const apiService = useMemo(() => new ApiService(authService), [authService])
    const location = useLocation();
    // const [claims, setClaims] = useState([] as string[])

    const saveAccessToken = (user: User | null) => {
        const accessToken = user?.access_token;

        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            const {claim}: { claim: string | string[] } = decode(accessToken);
            let clms: string[] = []
            if (Array.isArray(claim)) {
                clms = (claim);
            } else {
                clms = ([claim]);
            }
            setState((prevState: IAuthType) => {
                return {...prevState, user: user, status: IAuthTypeStatus.LoggedIn, claims: clms}
            })
        } else {
            setState((prevState: IAuthType) => {
                return {
                    ...prevState,
                    user: null,
                    status: IAuthTypeStatus.InError,
                    error: new Error('Cant get access token')
                }
            })
        }
    }

    const LoginAction = async (): Promise<User | null> => {
        await authService.login();
        const user = await authService.getUser();
        if (!!user) {
            setState((prevState: IAuthType) => {
                return {...prevState, user: user, status: IAuthTypeStatus.LoggedIn}
            })
        }
        return user
    }

    const RefreshAction = async (): Promise<User | null> => {
        await authService.renewToken();
        const user = await authService.getUser();
        if (!!user) {
            saveAccessToken(user);
            setState((prevState: IAuthType) => {
                return {...prevState, user: user, status: IAuthTypeStatus.LoggedIn}
            })
        }
        return user
    }

    const LogoutAction = async () => {
        await authService.logout();
        setState((prevState: IAuthType) => {
            return {...prevState, user: null, status: IAuthTypeStatus.LoggedOut}
        })
    }

    const [state, setState] = React.useState({
        status: IAuthTypeStatus.Pending,
        error: null,
        user: null,
        LoginAction,
        LogoutAction,
        RefreshAction,
        authService,
        apiService,
        claims: [],
    } as IAuthType);

    useEffect(() => {
        const fetch = async () => {
            if (!authService)
                return;
            const user = await authService.getUser();
            if (state.status === IAuthTypeStatus.LoggedIn && !user?.expired) {
                return;
            }

            if (!!user) {
                saveAccessToken(user);
            } else {
                const newUser = await LoginAction();
                if (!!newUser) {
                    saveAccessToken(newUser);
                    setState((prevState: IAuthType) => {
                        return {...prevState, user: null, status: IAuthTypeStatus.LoggedOut}
                    })
                }
            }

        }
        fetch();
    }, [location.pathname])

    return (
        <AuthContext.Provider value={state}>
            {state.status === IAuthTypeStatus.Pending ? (
                'Loading...'
            ) : state.status === IAuthTypeStatus.InError ? (
                <div>
                    Oh no
                    <div>
                        <pre>{state.error?.message}</pre>
                    </div>
                </div>
            ) : (
                children
            )}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
